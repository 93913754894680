import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { CommonService } from '../_services';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(private authService: CommonService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // var name = location.pathname.split('/')[1];
    var authToken = JSON.parse(this.authService.getTokenUser())
    // console.log('check data---', authToken)
    if (authToken && authToken.refresh && authToken.refresh.token) {
      request = request.clone({ setHeaders: { Authorization: `Bearer ${authToken.refresh.token}` } });
    } else {
      request = request.clone({ setHeaders: { Authorization: 'Basic ' + btoa('singPass:singPass@12345') } });
    }
    return next.handle(request);
  }
}
