import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {
  tokens: any = JSON.parse(localStorage.getItem('on_d_r_u_t'))
  userData: any = JSON.parse(localStorage.getItem('on_d_r_u'))

  showNavAfterLogin: boolean = false;

  url: string;

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event: Event) => {
      this.url = this.location.path();
      // console.log(this.url)
      if (event instanceof NavigationStart) {
        this.url = event.url;
        // console.log(this.url)
        // Show loading indicator
        // console.log('Route change detected', event.url);
      }

      if (event instanceof NavigationEnd) {
        this.url = event.url;
        // console.log("u", this.url)
      }

    });
  }

  ngOnInit(): void {
    if (this?.tokens && this?.userData) {
      this.showNavAfterLogin = true
    } else {
      this.showNavAfterLogin = false
    }
  }

}
