import { Injectable } from '@angular/core';
import * as moment from 'moment';
// import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class CommonService {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    setDataUser(data: any) {
        localStorage.setItem('on_d_r_u', JSON.stringify(data)); //This is use for admin data
    }

    setDataAdmin(data: any) {
        localStorage.setItem('on_d_r_a', JSON.stringify(data)); //This is use for admin data
    }

    setTokenAdmin(token: string) {
        localStorage.setItem('on_d_r_a_t', token); //This is use for admin token
    }

    setTokenUser(token: string) {
        localStorage.setItem('on_d_r_u_t', token); //This is use for admin token
    }

    getAdminData() {
        return JSON.parse(localStorage.getItem('on_d_r_a') || '{}');
    }

    getTokenAdmin() {
        return localStorage.getItem('on_d_r_a_t');
    }

    getTokenUser() {
        return localStorage.getItem('on_d_r_u_t');
    }

    isLoggedInAdmin() {
        return this.getTokenAdmin() !== null;
    }

    isLoggedInUser() {
        return this.getTokenUser() !== null;
    }

    isCheckPermission() {
        var createdAt;
        var companyDetails = this.getAdminData();

        var currentDate = moment();
        var days = currentDate.diff(createdAt, 'days');
        if (days > 15 && companyDetails.type == 'superadmin') {
            // this.isCheckSubscription();
            // console.log('days-----===>>',days)
            if (days > 15) {
                return false;
            } else {
                return false;
            }
        } else {
            if (companyDetails.role) {
                return companyDetails.role;
            } else {
                return true;
            }
        }
    }
    checkAccess(moduleId: any, type: any) {
        var data = this.getAdminData().role;
        var loginDetails = this.getAdminData();
        // console.log('data-----===>>122==', data)
        if (data && loginDetails.type != 'superadmin') {
            for (let i = 0; i < data.resource.length; i++) {
                if (data.resource[i].moduleId == moduleId) {
                    return data.resource[i].permissions.includes(type) ? true : false
                }
            }
        } else {
            return true;
        }
    }

    adminLogout() {
        localStorage.removeItem('on_d_r_a_t');
        localStorage.removeItem('on_d_r_a');
    }
}
