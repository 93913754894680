import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { LoanRoutingModule } from './loan-routing.module';
import { SharedDirectiveModule } from '../shared-directive/shared-directive.module';
import { LoanComponent } from './loan.component';
import { ApplyLoanComponent } from './apply-loan/apply-loan.component';

import { NumberDirective } from "../../_directive/numbers-only.directive";


@NgModule({
  declarations: [
    LoanComponent,
    ApplyLoanComponent,
    NumberDirective,
    // ThousandsSeparatorDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    LoanRoutingModule,
    SharedDirectiveModule
  ],
  exports: [ApplyLoanComponent, NumberDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LoanModule { }
