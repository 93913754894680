import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThousandsSeparatorDirective } from "../../_directive/currency.directive";


@NgModule({
  declarations: [ThousandsSeparatorDirective],
  imports: [
    CommonModule
  ],
  exports:[ThousandsSeparatorDirective]
})
export class SharedDirectiveModule { }
