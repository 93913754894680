import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';


@Injectable()

export class UserAuthservice {

  constructor(private http: HttpClient, public toast: ToastrService) { }

  apiUrl: any = environment.apiUrl;

  extractData(res: any) {
    const body = res;
    return body || {};
  }

  login(request: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/login`, request).pipe(map(this.extractData));
  }

  checkUsername(request: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/username-check`, request).pipe(map(this.extractData));
  }

  createAccount(request: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/register`, request).pipe(map(this.extractData));
  }

  verifyOTP(request: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/user/otp/` + request.userId, request).pipe(map(this.extractData));
  }

  resendOTP(request: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/otp/` + request.userId).pipe(map(this.extractData));
  }

  forgotPassword(request: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/forgot-password`, request).pipe(map(this.extractData));
  }

  changePassword(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/change-password`, data).pipe(map(this.extractData));
  }

  resetPassword(data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/user/reset-password/` + data.id, data).pipe(map(this.extractData));
  }

  updateProfile(id: any, data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/user/profile/` + id, data).pipe(map(this.extractData));
  }

  getProfile(id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/profile/` + id).pipe(map(this.extractData));
  }

  logout(request: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/logout`, request).pipe(map(this.extractData));
  }

  getEnv() {
    return this.http.get(`${this.apiUrl}/callback/getEnv?env=`+ environment.env).pipe(map(this.extractData));
  }

  getQrCode() {
    return this.http.get(`https://api-staging.onlinecredit.com.sg/callback/generateqrcode`).pipe(map(this.extractData));
  }
  
  getPersonDataFromSingpass(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/callback/auth-getPersonData`, data).pipe(map(this.extractData));
  }

  generateCodeChallenge() {
    return this.http.get(`${this.apiUrl}/callback/generateCodeChallenge`).pipe(map(this.extractData));
  }
}

export const mediaUrl = environment.apiUrl + "/media/preview?filename="
