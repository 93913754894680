import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAuthservice } from '../../../_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply-loan',
  templateUrl: './apply-loan.component.html',
  styleUrls: ['./apply-loan.component.scss'],
})
export class ApplyLoanComponent implements OnInit {
  myForm: any = {
    months: 1,
    imId: '',
    price: 1000,
  };
  envDetails: any = {};
  submitted = false;
  loading: boolean;
  showError: boolean = false;
  apiCalled: boolean = false;
  value: number = 100;
  showAmount: any = 0;
  options1: Options = {
    floor: 1000,
    ceil: 100000,
    step: 500,
    translate: (value: number, label: LabelType): string => {
      return '$' + value;
    },
  };
  value2: number = 3;
  options2: Options = {
    floor: 1,
    ceil: 12,
    step: 1,
    translate: (value: number, label: LabelType): string => {
      return value + ' Month';
    },
  };

  applyLoan: FormGroup;
  tokens: any = JSON.parse(localStorage.getItem('on_d_r_u_t'));
  userData: any = JSON.parse(localStorage.getItem('on_d_r_u'));
  formattedAmount;

  constructor(
    private currencyPipe: CurrencyPipe,
    private _fb: FormBuilder,
    private _userAuthService: UserAuthservice,
    private router: Router,

  ) { }

  // Initialize the component when it is loaded
  ngOnInit(): void {
    // Fetch environment data and set up the form
    this.getEnvData();
    this.loading = false; // Set initial loading state
    // Create a form group for loan application
    this.applyLoan = this._fb.group({
      imId: ['', [Validators.required]], // Input for ID type
      annualIncome: ['', [Validators.required]], // Input for annual income
      price: ['', [Validators.required]], // Input for loan amount
      months: ['', [Validators.required]], // Input for loan tenure in months
    });
  }

  // Getters for easy access to form controls within the template
  get f() {
    return this.applyLoan.controls;
  }

  // Fetch environment data from the service
  getEnvData() {
    // Subscribe to the API call to get environment details
    this._userAuthService.getEnv().subscribe(
      (objS) => {
        //console.log('check response ===', objS);
        this.envDetails = objS; // Store environment details for later use
      },
      (err) => {
        this.loading = false; // Stop loading on error
        //console.log('err', err);
      }
    );
  }

  // Transform the entered amount into a formatted currency value
  transformAmount(element: any): void {
    // Check if the entered value is a valid number
    if (isNaN(element.target.value)) {
      return alert('Please enter a valid number');
    }
    // Transform and display the entered value as a formatted currency
    this.myForm.income = this.currencyPipe.transform(this.myForm.income, '$');
    element.target.value = this.myForm.income;
  }




  startRange(type: any): void {
    // Transform the value using currencyPipe and store in showAmount
    this.showAmount = this.currencyPipe.transform(this.value, '$');
  }

  showToastMessage(msg) {
    // Show a warning toast message using the _userAuthService
    this._userAuthService.toast.warning(msg);
    this.loading = false; // Set loading to false
  }

  navigateToManualLoan() {
    // Check if data is available in localStorage
    const data = JSON.parse(localStorage.getItem('loanForm'));
    if (data) {
      // If data is available, navigate to the manual loan application page
      this.router.navigate(['/loan/apply-manual-loan']);
    } else {
      // If data is not available, show a warning toast message
      this._userAuthService.toast.warning('Please fill all required fields');
    }
  }

  checkBeforeSubmit() {
    this.loading = true; // Set loading to true
    if (this.f['imId'].value == '') {
      this._userAuthService.toast.warning('Please select Id Type'); // Show a warning toast message
      return;
    }
    if (this.f['annualIncome'].value == '') {
      this._userAuthService.toast.warning('Annual Income is required'); // Show a warning toast message
      return;
    }
  }

  onSubmit() {
    this.loading = true; // Set loading to true
    if (this.f['imId'].value == '') {
      this._userAuthService.toast.warning('Please select Id Type'); // Show a warning toast message
      return;
    }
    if (this.f['annualIncome'].value == '') {
      this._userAuthService.toast.warning('Annual Income is required'); // Show a warning toast message
      return;
    }
    //console.log(this.applyLoan.value); // Log the form value
    // Store the form value in localStorage
    localStorage.setItem('loanForm', JSON.stringify(this.applyLoan.value));
  }

  callAuth() {
    // Generate a random state and construct the authorization URL
    // var state = Math.floor(Math.random() * 100000);
    // var authoriseUrl =
    //   this.envDetails.authApiUrl +
    //   '?client_id=' +
    //   this.envDetails.clientId +
    //   '&attributes=' +
    //   this.envDetails.attributes +
    //   '&purpose=' +
    //   this.envDetails.purpose +
    //   '&state=' +
    //   encodeURIComponent(state) +
    //   '&redirect_uri=' +
    //   this.envDetails.redirectUrl;

    // const win: Window = window;
    // win.location = authoriseUrl; // Redirect to the authorization URL
    this._userAuthService.generateCodeChallenge().subscribe((objS) => {
      console.log('============result=============', objS?.codeChallenge)
      localStorage.setItem("codeVerifier", objS?.codeVerifier)
      localStorage.setItem("sessionId", objS?.sessionId)
      var authorizeUrl = this.envDetails.authApiUrl + "?client_id=" + this.envDetails.clientId +
        "&scope=" + this.envDetails.scope +
        "&purpose_id=" + this.envDetails.purpose_id +
        "&code_challenge=" + objS?.codeChallenge +
        "&code_challenge_method=" + 'S256' +
        "&redirect_uri=" + this.envDetails.redirectUrl;
      console.log('=========authorizeUrl=======', authorizeUrl)
      const win: Window = window;
      win.location = authorizeUrl; // Redirect to the authorization URL
    },
      (err) => {
        this.loading = false; // Stop loading on error
        //console.log('err', err);
      }
    );
  }


  // formatInputValue(event: any) {
  //   let input = event.target as HTMLInputElement;
  //   let value = input.value;

  //   // Remove non-numeric characters from the input value
  //   value = value.replace(/[^0-9.]/g, '');
  //   //console.log('value=======', value)
  //   // Update the form control value
  //   this.applyLoan.get('annualIncome')?.setValue(value);
  // }

}
