import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appThousandsSeparator]'
})
export class ThousandsSeparatorDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input')
  onInput() {
    const inputValue = this.el.nativeElement.value;
    const formattedValue = this.formatNumber(inputValue);
    this.el.nativeElement.value = formattedValue;
  }

  private formatNumber(value: string): string {
    // Remove non-numeric characters from the input value
    const numericValue = value.replace(/[^0-9]/g, '');
  
    // Add commas for thousands and prepend the dollar sign
    const formattedValue = '$' + Number(numericValue).toLocaleString();
  
    return formattedValue;
  }

}
