import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    // private authService: AuthService,
    private location : Location) {}
  intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    return next.handle(request).pipe(catchError(err => {
      // if (err.status === 401 || err.status === 403) {
      //   var name = location.pathname.split('/')[1];
      //   if(name == 'buyer') this.authService.buyerLogout();
      //   else if(name == 'seller') this.authService.selleLogout();
      //   // auto logout if 401 response returned from api
      //   location.reload(true);
      // }
      const error = err.error.message || err.statusText;
      return next.handle(error);
    }));
  }
}
