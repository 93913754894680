import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';


@Injectable({ providedIn: 'root' })

export class LoanApplicationservice {

  constructor(private http: HttpClient, public toaster: ToastrService) { }

  apiUrl: any = environment.apiUrl;

  extractData(res: any) {
    const body = res;
    return body || {};
  }

  getLoanApplicationsList(request: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/loan-application?page=` + request.page + '&&sortBy=' + request.sortBy + '&&limit=' + request.limit + '&&searchBy=' + request.searchBy).pipe(map(this.extractData));
  }

  getLoanApplicationsListWithoutPagination(request: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/loan-application/list/dropdown?searchBy=` + request.searchBy).pipe(map(this.extractData));
  }

  getLoanApplicationDetails(request: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/loan-application/` + request).pipe(map(this.extractData));
  }

  addNewLoanApplication(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/loan/loan-application`, data).pipe(map(this.extractData));
  }

  updateLoanApplication(data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/user/loan-application/` + data.id, data).pipe(map(this.extractData));
  }

  deleteLoanApplication(data: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/user/loan-application/` + data).pipe(map(this.extractData));
  }

  getPersonDataFromSingpass(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/callback/getPersonData`, data).pipe(map(this.extractData));
  }


  getLoanApplicationStatus(request: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/loan/loan-application?page=${request.page}&sortBy=${request.sortBy}&limit=${request.limit}&searchBy=${request.searchBy}`);
  }

  getLoanApplicationMLCBDetails(request: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/loan/loan-application`, request);
  }


  createSupportTicket(body) {
    return this.http.post(this.apiUrl + "/ticket/user", body)
  }

  getTickets(request) {
    return this.http.get(this.apiUrl + `/ticket/user?page=${request.page}&sortBy=${request.sortBy}&limit=${request.limit}&searchBy=${request.searchBy}`
    );
  }
  // this.baseAPIUrl + `ticket/for-admin?page=${request.page}&sortBy=${request.sortBy}&limit=${request.limit}&searchBy=${request.searchBy}`


  updateTicket(id: any, body: any) {
    return this.http.patch(this.apiUrl + '/ticket/user/' + id, body);
  }

  getTicketById(id: any) {
    return this.http.get(this.apiUrl + '/ticket/user/' + id)
  }

  deleteTicket(id: any) {
    return this.http.delete(this.apiUrl + '/ticket/user/' + id);
  }


  // Notification

  getNotificationList(request: any) {
    return this.http.get(this.apiUrl + `/user/notifications?page=${request.page}&limit=${request.limit}&status=${request.status}`)
  }
  // getNotificationList(request: any): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/user/notifications?page=` + request.page + '&&sortBy=' + request.sortBy + '&&limit=' + request.limit + '&&searchBy=' + request.searchBy + 'status=' + request.status).pipe(map(this.extractData));
  // }

  // Get ShortCode Abbribation
  getMyInfoAPICode() {
    return this.http.get('../../../assets/myinfo-api-code-tables.json')
  }



}
