import { Component, OnInit } from '@angular/core';
import { NotificationService } from './_services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'singpass-frontend';
  message: any = null;
  constructor(private notificationService: NotificationService) { }
  /**
  * Initialize the component when it is first loaded
  */
  ngOnInit(): void {
    // Request permission for push notifications
    this.notificationService.requestPermission();

    // Start listening for push notifications
    this.notificationService.listen();
  }

}
