<section class="inner_header">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <!-- <a class="navbar-brand" href="/"
        ><img src="../../../assets/images/logo.png" class="img-fluid" alt=""
      /></a> -->
      <button
        class="navbar-toggler collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
        <span class="close_icon"><i class="fa-solid fa-xmark"></i></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              [routerLink]="['/home']"
              routerLinkActive="active"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/setting/profile']"
              routerLinkActive="active"
              >Profile</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/ticket-support']"
              routerLinkActive="active"
              >Ticket Support</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/loan/apply-loan']"
              routerLinkActive="active"
              >Loan Application</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/notification']"
              routerLinkActive="active"
              ><i title="Notification" class="fa-solid fa-bell"></i></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" type="button" (click)="logout()"><i title="Logout" class="fa fa-sign-out" aria-hidden="true"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</section>
