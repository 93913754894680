import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  message: any = null;
  fcmToken: any = ""

  constructor(private toaster: ToastrService) { }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            console.log("Hurraaa!!! we got the token.....");
            console.log(currentToken);
            this.fcmToken = currentToken
            sessionStorage.setItem("f_b_t_a", currentToken)
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          // this.toaster.error(err)
          console.log('An error occurred while retrieving token. ', err);
        });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
    });
  }
}
