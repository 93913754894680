<section class="page_404">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <img src="../../../assets/images/404_img.png" class="img-fluid" alt="404">
                <h2>Oops! This Page is Not Found.</h2>
                <p>The requested page dose not exist.</p>
                <a href="index.html" class="btn btn-primary"><i class="fa-solid fa-house-chimney"></i>Back to Home</a>
            </div>
        </div>
    </div>
</section>
<!-- Page NOt Found -->