<div class="main-wrapper">
  <!-- <app-navbar></app-navbar> -->
  <!-- <div class="container-fluid page-body-wrapper"> -->
  <!-- <app-sidebar></app-sidebar> -->
  <div class="page-wrapper">
    <div class="content">
      <!-- <app-breadcrumb></app-breadcrumb> -->
      <router-outlet></router-outlet>
    </div>
    <!-- <app-footer></app-footer> -->
  </div>
  <!-- </div> -->
</div>
