import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../_services';

@Injectable()

export class AuthGuard implements CanActivate {
  constructor(private authService: CommonService, private route: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedInUser() || next['_routerState']['url'] === '/loan/apply-manual-loan') {
      // console.log(state)
      return true;
    } else {

      this.route.navigate(['/auth/login']);
      return false;
    }
  }
}

