<section class="header_box">
  <img
    src="../../../assets/images/nabR_img.png"
    class="img-fluid navR_img"
    alt=""
  />
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <!-- <a class="navbar-brand" href="/"><img src="../../../assets/images/logo.png" class="img-fluid" alt=""></a> -->
      <button
        class="navbar-toggler collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
        <span class="close_icon"><i class="fa-solid fa-xmark"></i></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <!-- <li class="nav-item">
                <a class="nav-link active" aria-current="page" [routerLink]="['/home']" routerLinkActive="router-link-active">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/setting/profile']" routerLinkActive="router-link-active">Profile</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/notification']" routerLinkActive="router-link-active">Notifications</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/loan/apply-loan']" routerLinkActive="router-link-active">Loan Application</a>
              </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/auth/login']"
              routerLinkActive="router-link-active"
              >Login</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</section>
