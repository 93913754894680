import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { BlankComponent } from './layout/blank/blank.component';
import { FullComponent } from './layout/full/full.component';
import { AuthGuard } from './_helpers';
// import { ApplyLoanComponent } from './modules/loan/apply-loan/apply-loan.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/loan/apply-loan',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./modules/root-component/root-component.module').then(m => m.RootComponentModule),
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   component: ApplyLoanComponent,
  //   // redirectTo: '/loan/apply-loan',
  //   pathMatch: 'full'
  // },
  {
    path: 'auth',
    component: FullComponent,
    loadChildren: () => import('./modules/auth/auth.module')
      .then(mod => mod.AuthModule)
  },

  {
    path: 'home',
    component: BlankComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/home/home.module')
      .then(mod => mod.HomeModule)
  },
  {
    path: 'loan',
    component: BlankComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/loan/loan.module')
      .then(mod => mod.LoanModule)
  },
  {
    path: 'notification',
    component: BlankComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/notification/notification.module')
      .then(mod => mod.NotificationModule)
  },
  {
    path: 'terms-of-use',
    component: FullComponent,
    loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module')
      .then(mod => mod.TermsAndConditionsModule)
  },
  {
    path: 'security-advisory',
    component: FullComponent,
    loadChildren: () => import('./modules/security-advisory/security-advisory.module')
      .then(mod => mod.SecurityAdvisoryModule)
  },
  {
    path: 'setting',
    component: BlankComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/setting/setting.module')
      .then(mod => mod.SettingModule)
  },
  {
    path: 'support',
    component: BlankComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/support/support.module')
      .then(mod => mod.SupportModule)
  },
  {
    path: 'ticket-support', component: BlankComponent,
    canActivate: [AuthGuard], loadChildren: () => import('./modules/ticket-support/ticket-support.module').then(m => m.TicketSupportModule)
  },
  {
    path: 'docs-contracts', component: BlankComponent,
    canActivate: [AuthGuard], loadChildren: () => import('./modules/doc-contracts/doc-contracts.module').then(m => m.DocContractsModule)
  },
  {
    path: 'contracts', component: BlankComponent,
    canActivate: [AuthGuard], loadChildren: () => import('./modules/contract-documents/contract-documents.module').then(m => m.ContractDocumentsModule)
  },
  {
    path: 'callback', loadChildren: () => import(`./modules/loan/apply-manual-loan/apply-manual-loan.module`).then(mod => mod.ApplyManualLoanModule)
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
