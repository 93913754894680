// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiUrl: "http://localhost:3081/v1",
  
  socketApiUrl: "https://socket-staging.sgpcredit.com.sg/",
  apiUrl: "https://staging-api.sgpcredit.com.sg/v1",
  redirectUri: 'https://staging.sgpcredit.com.sg/auth/callback',
  
  clientId: 'ZVArP3kUcPsKdVZ3TrVIZLbGSAArAJun',
  env: 'SANDBOX',
  firebase: {
    apiKey: "AIzaSyDJe41XGW7GX0eqYYSsnQ0ELgoxuQUQdKs",
    authDomain: "onlinecredit-admin.firebaseapp.com",
    projectId: "onlinecredit-admin",
    storageBucket: "onlinecredit-admin.appspot.com",
    messagingSenderId: "9875777887",
    appId: "1:9875777887:web:74968a095f5dea1f5b6eea",
    measurementId: "G-NLPLNFT3E1",
    vapidKey: "BN1mOk-SWR4sQmpCVaCb7ww4dBExV4c1v7Yj5usdjLTnlektHGkc8PaIWcaeuvaqVPpCiPrFMw-lC52LSHF18as"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
