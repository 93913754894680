import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoanComponent } from './loan.component';

const routes: Routes = [
  {
    path: '', component: LoanComponent, children: [
      {
        path: 'apply-loan',
        loadChildren: () => import(`./apply-loan/apply-loan.module`)
          .then(mod => mod.ApplyLoanModule)
      },

      {
        path: 'apply-manual-loan',
        loadChildren: () => import(`./apply-manual-loan/apply-manual-loan.module`)
          .then(mod => mod.ApplyManualLoanModule)
      },

      {
        path: 'verify-loan-app',
        loadChildren: () => import(`./apply-manual-loan/apply-manual-loan.module`)
          .then(mod => mod.ApplyManualLoanModule)
      },

      {
        path: 'confirm-loan-application',
        loadChildren: () => import(`./confirm-loan-details/confirm-loan-details.module`)
          .then(mod => mod.ConfirmLoanDetailsModule)
      },
      
      {
        path: 'loan-history',
        loadChildren: () => import(`./loan-history/loan-history.module`)
          .then(mod => mod.LoanHistoryModule)
      },

      // {
      //   path: '**',
      //   loadChildren: () => import(`./apply-loan/apply-loan.module`)
      //     .then(mod => mod.ApplyLoanModule)
      // }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoanRoutingModule { }
