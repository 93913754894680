import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor, AuthGuard, ErrorInterceptor, TimeoutInterceptor, DEFAULT_TIMEOUT } from './_helpers';
import { CommonService, UserAuthservice } from './_services';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
// import { BlankComponent } from './layout/blank/blank.component';
// import { FullComponent } from './layout/full/full.component';
// import { FooterComponent } from './layout/footer/footer.component';
// import { SidebarComponent } from './layout/sidebar/sidebar.component';
// import { NavbarComponent } from './layout/navbar/navbar.component';
// import { NavbarAfterLoginComponent } from './layout/navbar-after-login/navbar-after-login.component';
import { LayoutModule } from './layout/layout.module';
import { LoanModule } from './modules/loan/loan.module';
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { LoaderComponent } from './modules/loader/loader.component';
initializeApp(environment.firebase);
// import { NumberDirective, ThousandsSeparatorDirective } from "./_directive";

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoaderComponent,
    // NumberDirective,
    // ThousandsSeparatorDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    LayoutModule,
    CommonModule,
    LoanModule
  ],
  providers: [
    CommonService,
    AuthGuard,
    CurrencyPipe,
    UserAuthservice,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor, multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: TimeoutInterceptor,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 30000
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
