import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-navbar-after-login',
  templateUrl: './navbar-after-login.component.html',
  styleUrls: ['./navbar-after-login.component.scss']
})
export class NavbarAfterLoginComponent implements OnInit {

  isOpened: boolean = false;
  constructor(private toast: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  rightMenu() {
    this.isOpened = !this.isOpened;
  }

  logout() {
    Swal.fire({
      title: 'Are you sure!',
      text: ' Do you want to logout?',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#FF8C00',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // const token = JSON.parse(localStorage.getItem('on_d_r_u_t'))?.refresh.token
        localStorage.removeItem('on_d_r_u_t')
        localStorage.removeItem('on_d_r_u')
        localStorage.removeItem('loanForm')
        this.toast.success("Logged out")
        this.router.navigate(['/auth/login'])
      }
    })
  }

}
