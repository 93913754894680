<section class="apply_loan">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-5 col-xl-5">
        <div class="lBox">
          <h4>Loan Application</h4>
          <p>Select your loan application filling method</p>
          <div class="login_btn">
            <a type="button" (click)="navigateToManualLoan()" routerLinkActive="router-link-active">Fill Application
              Manually</a>
          </div>
          <span class="seprator">OR</span>

          <h4>
            Retrieve your particular via Myinfo
            <span><i class="fa-solid fa-asterisk"></i></span>
          </h4>

          <p>
            with Myinfo, your application form will be pre-filled in accordance
            with your personal details store in Myinfo,Simply have the following
            details ready to prior to retrieval of Myinfo
          </p>

          <!-- <div class="login_btn retrive_btn">
            <a
              [routerLink]="['/auth/login']"
              routerLinkActive="router-link-active"
              >Retrieve Myinfo with Singpass</a
            >
          </div> -->
          <div class="login_btn retrive_btn">
            <a style="cursor: pointer" (click)="callAuth()" routerLinkActive="router-link-active">Retrieve Myinfo with
              Singpass</a>
          </div>
        </div>
      </div>

      <div class="col-md-7 col-xl-6">
        <div class="form_box">
          <div class="innerBox">
            <div class="row">
              <div class="col-md-12">
                <!-- <img
                  src="../../../../assets/images/logo.png"
                  class="img-fluid"
                  alt=""
                /> -->
              </div>
            </div>
            <form [formGroup]="applyLoan">
              <div class="tab-content" id="myTabContent">
                <div class="login_form">
                  <div class="input_box">
                    <label for="">
                      I am a <span style="color: red">*</span>
                    </label>
                    <select class="form-select" aria-label="Default select example" name="imId" formControlName="imId">
                      <option value="" disabled>Choose ID Type</option>
                      <option value="1">Singaporean/PR</option>
                      <option value="2">Foreigner</option>
                      <option value="3">Business</option>
                      <!-- <option value="2">Singapore PR No</option>
                      <option value="3">Foreign Identification Number</option>
                      <option value="4">Passport No.</option>
                      <option value="5">Unique Entity No</option>
                      <option value="6">Others</option> -->
                    </select>
                    <!-- <i class="fa-solid fa-angle-down"></i> -->
                    <!-- <span
                      class="validation_error form-text"
                      *ngIf="loading && f['imId'].errors"
                    >
                      <span
                        class="validation_error"
                        *ngIf="loading && f['imId'].errors['required']"
                        >{{ showToastMessage("Id is required") }}</span
                      >
                    </span> -->
                  </div>

                  <div class="input_box">
                    <label for="">
                      My Annual Income is <span style="color: red">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="annualIncome" placeholder="Income"
                      appThousandsSeparator maxlength="10" />
                    <!-- <span>* (Income)</span> -->
                    <!-- <input
                      type="text"
                      class="form-control"
                      formControlName="annualIncome"
                      (change)="transformAmountVal($event)"
                      placeholder=""
                      numbersOnly
                      maxlength="10"
                    /> -->
                    <!-- <span
                      class="validation_error form-text"
                      *ngIf="loading && f['annualIncome'].errors"
                    >
                      <span
                        class="validation_error"
                        *ngIf="loading && f['annualIncome'].errors['required']"
                        >{{
                          showToastMessage("Annual Income is required")
                        }}</span
                      >
                    </span> -->
                  </div>

                  <div class="input_box">
                    <label for="">
                      I require a personal loan of
                      <span style="color: red">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="price" placeholder="Desired Loan Amount"
                      appThousandsSeparator maxlength="10" />
                    <!-- <span> (Desired Loan Amount)</span> -->
                    <!-- <ngx-slider
                      [(value)]="value"
                      name="price"
                      formControlName="price"
                      [options]="options1"
                      class="form-range"
                      id="customRange1"
                      (valueChange)="startRange('price')"
                    >
                    </ngx-slider> -->
                    <span class="validation_error form-text" *ngIf="loading && f['price'].errors">
                      <span class="validation_error" *ngIf="loading && f['price'].errors['required']">Required</span>
                    </span>
                  </div>

                  <div class="input_box repay">
                    <label for="">
                      I plan to repay in <span style="color: red">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="months" placeholder="Tenure" numbersOnly
                      maxlength="3" /><span style="font-weight: bold"> months.</span>
                    <!-- <ngx-slider
                      [(value)]="value2"
                      name="months"
                      formControlName="months"
                      [options]="options2"
                      class="form-range"
                      id="customRange2"
                      (valueChange)="startRange('months')"
                    >
                    </ngx-slider> -->
                    <span class="validation_error form-text" *ngIf="loading && f['months'].errors">
                      <span class="validation_error" *ngIf="loading && f['months'].errors['required']">Required</span>
                    </span>
                  </div>

                  <div class="login_btn" *ngIf="applyLoan.invalid">
                    <a class="action_btn" type="button" (click)="checkBeforeSubmit()">
                      Let's Get Started
                    </a>
                  </div>

                  <div class="login_btn" *ngIf="!applyLoan.invalid">
                    <a class="action_btn" type="button" (click)="onSubmit()" data-bs-toggle="modal"
                      data-bs-target="#exampleModal">
                      Let's Get Started
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <p class="btm_text">
          <span><i class="fa-solid fa-asterisk"></i></span>For Loan amount of
          $20,0000, APR of 6.95%, 5 year tenure and an annual fee of $199, the
          total loan payable will be $23,479.80 with a monthly cost of $391.33.
          An individualised APR will be presented at the end of your
          application. Max. APR 48% Min. repayment period 3 months. max. 73
          months.
        </p>
      </div>
    </div>
  </div>

  <div class="business_modal">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="cardBox">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <h4>Loan Application</h4>
                  <p>Select your loan application filling method</p>
                  <div class="login_btn">
                    <a data-bs-dismiss="modal" (click)="navigateToManualLoan()" type="button">Fill Application
                      Manually</a>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="rBox">
                    <h4>
                      Retrieve your particular via Myinfo
                      <span><i class="fa-solid fa-asterisk"></i></span>
                    </h4>

                    <p>
                      with Myinfo, your application form will be pre-filled in
                      accordance with your personal details store in Myinfo,
                      Simply have the following details ready to prior to
                      retrieval of Myinfo
                    </p>

                    <div class="login_btn retrive_btn">
                      <a style="cursor: pointer" (click)="callAuth()">Retrieve Myinfo with Singpass</a>
                    </div>
                  </div>
                </div>
              </div>
              <span class="seprator">OR</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>